import InstagramCards from "./InstagramCards/InstagramCards";

const InstagramUpdates = () => {
  return (
    <div>
      <InstagramCards />
    </div>
  );
};

export default InstagramUpdates;
